import {useDispatch} from "reactn";

const userReducer = (user, action) => {
    return ({...user, ...action});
};

export const useUserDispatcher = () => {
    return useDispatch(userReducer, 'user');
};

export async function login({username, password}) {
    const data = new FormData();
    data.append('username', username);
    data.append('password', password);
    let req = await fetch('https://auth.bloep.app/sign-in', {
        method: 'post',
        cache: 'no-cache',
        body: data,
    });
    console.log(req);
    if(req.status !== 200) {
        throw new Error();
    }
    req = await req.json();
    return req;
}