import React from "react";
import {
    Route,
    Redirect
} from "react-router-dom";
import {useGlobal} from "reactn";

const ProtectedRoute = ({children, ...props}) => {
    const [user, ] = useGlobal('user');
    return (
        <Route
            {...props}
            render={({location}) => {
                return user.loggedIn ? (children) : (<Redirect to={{pathname: '/login', state: {from: location}}} />);
            }}
        />
    )
}


export default ProtectedRoute;
