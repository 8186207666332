import React, {useEffect} from 'react';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Popover from "@material-ui/core/Popover";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import makeStyles from "@material-ui/core/styles/makeStyles";
import dataService from "./utils/data";
import authService from "./utils/auth";
import {Link} from "react-router-dom";
import App from "./app/App";

const useStyles = makeStyles({
    button: {
        '&:focus': {
            outline: 'none'
        }
    }
});

function Default() {
    const classes = useStyles();
    const [userMenu, setUserMenu] = React.useState(null);

    const userMenuClick = (event) => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    useEffect(() => {
        (async () => {
            //console.log('hier');

            //console.log('get', await dataService.get('foo'));

            //console.log('put', await dataService.put('foo', 'bar' + Math.random()));

            //console.log('get', await dataService.get('foo'));

            //console.log('delete', await dataService.delete('foo'));

            //console.log('get', await dataService.get('foo'));

            // console.log('hier');

            // console.log('userdata', authService.getUserdata());

            // console.log('hier');
        })();
    }, []);

  return (
      <React.Fragment>
          <AppBar className="flex relative z-10" style={{backgroundColor: '#fff', position: "relative"}} color="default">
              <Toolbar className="p-0" style={{paddingRight: 0}}>
                  <div className="flex flex-1">
                      <Typography variant="h4">Timecalc</Typography>
                  </div>
                  <div className="flex">
                      <div style={{backgroundColor: '#ccc', width: 1, height: '4rem'}}/>
                          <Button onClick={userMenuClick} className={classes.button}>
                              {/*<Avatar>AD</Avatar>*/}
                              <Avatar/>

                              <div className="hidden md:flex flex-col mx-5 items-start">
                                  <Typography component="span" className="normal-case font-600 flex">
                                      {authService.getUserdata().firstname} {authService.getUserdata().lastname}
                                  </Typography>
                                  <Typography className="text-xs capitalize" color="textSecondary" style={{fontSize: '0.75rem'}}>
                                      Admin
                                  </Typography>
                              </div>

                              <Icon className="text-2xl hidden sm:flex" variant="action">
                                  keyboard_arrow_down
                              </Icon>
                          </Button>

                          <Popover
                              open={Boolean(userMenu)}
                              anchorEl={userMenu}
                              onClose={userMenuClose}
                              anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center'
                              }}
                              transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center'
                              }}
                              classes={{
                                  paper: 'py-2'
                              }}
                          >
                              {/*<MenuItem component=Link to=/url>*/}
                              <MenuItem role="button">
                                  <ListItemIcon className="min-w-40">
                                      <Icon>account_circle</Icon>
                                  </ListItemIcon>
                                  <ListItemText primary="My Profile"/>
                              </MenuItem>
                              <MenuItem role="button" component={Link} to="/logout">
                                  <ListItemIcon className="min-w-40">
                                      <Icon>exit_to_app</Icon>
                                  </ListItemIcon>
                                  <ListItemText primary="Logout"/>
                              </MenuItem>
                          </Popover>
                  </div>
              </Toolbar>
          </AppBar>
          <section className="flex-auto w-full overflow-x-hidden" style={{height: 'calc(100% - 4rem)'}}>
              <App/>
          </section>
      </React.Fragment>

  );
}

export default Default;
