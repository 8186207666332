import {get, put, req} from "./request";

class data {
    appUuid = null;

    init(appUuid) {
        this.appUuid = appUuid;
    }

    async get(key, defaultValue = null) {
        const request = await get(this._getUrl(key));

        if (request.status === 200 && request.ok) {
            return await request.text();
        }

        return defaultValue;
    }

    async put(key, value) {
        const request = await put(this._getUrl(key), value);
        return request.ok && request.status === 200;
    }

    async delete(key) {
        const request = await req('delete', this._getUrl(key));
        return request.ok && request.status === 200;
    }

    _getUrl(key) {
        return `https://data.bloep.app/${this.appUuid}/${key}`;
    }
}

const instance = new data();

export default instance;
