import dayjs from "dayjs";

const units = {
    's': 1,
    'm': 60,
    'h': 3600,
    'd': 3600 * 8,
    'w': 3600 * 8 * 5,
}
const parseToSec = (amount, unit) => {
    return amount * units[unit];
}
export const parseJiraTime = (timeString) => {
    const parser = /([-0-9]+)(\w+)/;
    const parts = timeString.split(' ');
    const parsed = parts
        .map(part => parser.exec(part))
        .filter(matched => matched !== null);
    if (parsed.length === 0) {
        return timeString;
    }
    return parsed
        .map(matched => parseToSec(matched[1], matched[2]))
        .reduce((p, v) => p + v, 0);
};
export const formatJiraTime = (sec) => {
    if (sec === 0 || sec === '0') {
        return 0;
    }
    if (sec === '') {
        return '';
    }
    const sortedUnits = Object.entries(units)
        .sort(([, a], [, b]) => {
            return a <= b ? 1 : -1;
        });

    const jiraString = [];
    for (const [unit, amount] of sortedUnits) {
        if (Math.abs(sec) >= amount) {
            const times = Math.floor(sec / amount);
            sec -= times * amount;
            jiraString.push(`${times}${unit}`);
        }
    }
    return jiraString.join(' ');
}
let sprintDays = [];
let sprintDayMax = '0000-00-00';
export const isSprintdayBuilder = (initialReviewDay) => {
    return (month, date) => {
        const max = dayjs(month + '-01').daysInMonth();
        const maxDate = `${month}-${max}`;
        if (sprintDayMax < maxDate) {
            sprintDayMax = maxDate;
            let curDate = dayjs(initialReviewDay);
            while (curDate.format('YYYY-MM-DD') <= maxDate) {
                sprintDays.push(curDate.format('YYYY-MM-DD'));
                curDate = curDate.add(14, 'day');
            }
        }

        return sprintDays.includes(date);
    };
};