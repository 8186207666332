import React, {useState} from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {useForm} from "react-hook-form";
import {useUserDispatcher} from "./store";
import authService from './utils/auth';

import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#c9c9c9'
    },
    form: {
        width: 400,
        background: '#fefefe'
    },
    headline: {
        marginBottom: '1rem',
        display: 'block'
    },
    button: {
        marginBottom: '2rem',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer +1,
        color: '#fff',
    }
}));


const Login = () => {
    const history = useHistory();
    const location = useLocation();

    const {from} = location.state || {from: {pathname: '/'}};

    const updateUser = useUserDispatcher();
    const {handleSubmit, register, errors} = useForm();
    const classes = useStyles();

    const btnClass = clsx(classes.button, 'w-full');

    const formClass = clsx(
        'p-5 shadow-2xl',
        classes.form
    );

    const [backdropVisible, setBackdropVisible] = useState(false);
    const formSubmit = (e) => {
        setBackdropVisible(true);
        authService.login(e.username, e.password)
            .then((userdata) => {
                setBackdropVisible(false);
                updateUser({...userdata, loggedIn: true});
                history.replace(from);
            })
            .catch(err => {
                setBackdropVisible(false);
                console.error(err);
            })
    }

    return (
        <div className={classes.root}>
            <form className={formClass} onSubmit={handleSubmit(formSubmit)}>
                <Typography variant="button" color="textPrimary" className={classes.headline}>Login</Typography>
                <TextField
                    label="Username"
                    name="username"
                    className={btnClass}
                    inputRef={register({
                        required: 'Required',
                    })}
                    error={Boolean(errors.username)}
                    helperText={errors.username && errors.username.message}
                />
                <TextField
                    label="Password"
                    name="password"
                    className={btnClass}
                    type="password"
                    inputRef={register({
                        required: 'Required'
                    })}
                    error={Boolean(errors.password)}
                    helperText={errors.password && errors.password.message}
                />
                <Button color="primary" variant="contained" className="w-full" type="submit">Login</Button>
                <Backdrop open={backdropVisible} className={classes.backdrop}>
                    <CircularProgress/>
                </Backdrop>
            </form>
        </div>
    )
};

export default Login;
