import jwtDecode from 'jsonwebtoken/decode'
import {post} from "./request";
import {getGlobal, setGlobal} from "reactn"

class auth {
    _jwt = null;
    _userdata = null;

    init() {
        const jwt = localStorage.getItem('auth');
        if (!jwt) {
            console.log('[auth] no jwt found');
            return;
        }
        const userdata = jwtDecode(jwt);
        if (userdata.exp * 1000 < Date.now()) {
            console.log('[auth] token expired');
            return;
        }
        this._jwt = jwt;
        this._userdata = userdata;

        setGlobal({...getGlobal(), user: {...userdata.payload, loggedIn: true}});
    }

    async login (username, password) {
        let req = await post('https://auth.bloep.app/sign-in', {username, password}, false);
        if (req.status !== 200) {
            throw new Error(`Unable to login\n${req.status} ${req.statusText}\n${req.body}`);
        }
        req = await req.json();
        if (!req.jwt) {
            throw new Error('Unable to login: no jwt given');
        }
        this._jwt = req.jwt;
        this._userdata = jwtDecode(this._jwt)
        localStorage.setItem('auth', this._jwt);
        setGlobal({...getGlobal(), user: {...this._userdata.payload, loggedIn: true}});
        return req;
    }

    getToken() {
        return this._jwt;
    }

    getUserdata() {
        return this._userdata.payload || this._userdata || null;
    }

    isValid() {
        return this._userdata.exp * 1000 < Date.now();
    }

    logout() {
        localStorage.removeItem('auth');
        this._jwt = null;
        this._userdata = null;
    }
}

const instance = new auth();

export default instance;
