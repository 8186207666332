import React, {useEffect, useState} from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";

import dayjs from "dayjs";
import TextField from "@material-ui/core/TextField";

import dataService from "../utils/data";
import {formatJiraTime, isSprintdayBuilder, parseJiraTime} from "./jira";
import Typography from "@material-ui/core/Typography";

const initialMonth = '2020-04';
const initialReviewDay = '2020-02-18';

const isSprintday = isSprintdayBuilder(initialReviewDay);

let restDays = 0;
let restDaysWithoutSprintDays = 0;
const getRows = (dataStore, month) => {
    restDays = 0;
    restDaysWithoutSprintDays = 0;;

    const rows = [];
    const max = dayjs(month + '-01').daysInMonth();
    for (let day = 1; day <= max; ++day) {
        const curDate = dayjs(month + '-01').date(day);
        const key = curDate.format('YYYY-MM-DD');
        const stored = dataStore[key] || {};

        if (stored.zeit === undefined) {
            restDays++;
            if (!isSprintday(month, key)) {
                restDaysWithoutSprintDays++;
            }
        }

        rows.push({key, datum: curDate.format('DD.MM.YYYY'), zeit: stored.zeit, naZeit: stored.naZeit, sprintwechsel: isSprintday(month, key)});
    }
    return rows;
};

const isWeekend = value => {
    return [0,6].includes(dayjs(value).day());
};

const cols = [
    {
        key: 'datum',
        label: 'Datum',
        minWidth: 200,
    },
    {
        key: 'zeit',
        label: 'Zeit',
        minWidth: 200,
        editable: true,
        value: formatJiraTime
    },
    {
        key: 'naZeit',
        label: 'NA-Zeit',
        minWidth: 200,
        editable: true,
        value: formatJiraTime
    },
    {
        key: 'sprintwechsel',
        label: 'Sprintwechsel',
        minWidth: 200,
        value: value => value ? 'X' : '',
    },
];

const App = () => {
    const [curInputs, setCurInputs] = useState({});

    const [data, setData] = useState({});
    const [month, setMonth] = useState(initialMonth);
    const [timePerMonth, setTimePerMonth] = useState('');
    const [timePerSprintDay, setTimePerSprintDay] = useState('');
    const rows = getRows(data, month);

    let gelZeit = 0;
    let gelZeitWithNA = 0;
    for (const row of rows) {
        gelZeit += (row.zeit || 0) - (row.naZeit || 0);
        gelZeitWithNA += parseInt(row.zeit || 0);
    }

    const diff = parseJiraTime(timePerMonth) - gelZeit;
    const diffWithNA = parseJiraTime(timePerMonth) - gelZeitWithNA;

    const amountSprintDays = restDays - restDaysWithoutSprintDays;
    const hPerD = Math.max(0, diff/ restDays);
    const hPerDWithoutSprintDays = Math.max(0, (diff - amountSprintDays * parseJiraTime(timePerSprintDay)) / restDaysWithoutSprintDays);
    const hPerDWithNA = Math.max(0, diffWithNA / restDays);
    const hPerDWithNAWithoutSprintDays = Math.max(0, (diffWithNA - amountSprintDays * parseJiraTime(timePerSprintDay)) / restDaysWithoutSprintDays);

    const onChange = (e) => {
        const name = e.currentTarget.name;
        const date = name.substring(0,name.indexOf('_'));
        const typ = name.substring(name.indexOf('_') + 1);
        const value = e.currentTarget.value;

        const storeEntry = data[date] || {};
        storeEntry[typ] = value.length > 0 ? parseJiraTime(value) : undefined;

        const newData = {
            ...data,
            [date]: storeEntry
        };
        dataService.put('times', newData)
            .catch(console.error)
        setData(newData);
    };

    const onMonthChanged = (e) => {
        setMonth(e.currentTarget.value);
        dataService.put('currentMonth', e.currentTarget.value)
            .catch(console.error)
    };

    const onTimePerMonthChanged = e => {
        setTimePerMonth(e.currentTarget.value);
        dataService.put('timePerMonth', e.currentTarget.value)
            .catch(console.error)
    }

    const onTimePerSprintDayChanged = e => {
        setTimePerSprintDay(e.currentTarget.value);
        dataService.put('timePerSprintDay', e.currentTarget.value)
            .catch(console.error)
    }

    useEffect(() => {
        dataService.get('times', '{}')
            .then(loadedData => {
                loadedData = JSON.parse(loadedData);
                setData({
                    ...data,
                    ...loadedData
                });
            });
        dataService.get('currentMonth', '')
            .then(loadedData => {
                setMonth(loadedData);
            });
        dataService.get('timePerMonth', '')
            .then(loadedData => {
                setTimePerMonth(loadedData);
            });
        dataService.get('timePerSprintDay', '')
            .then(loadedData => {
                setTimePerSprintDay(loadedData);
            });
    }, [setData]);

    for (const rowKey in rows) {
        for (const col of cols) {
            if (col.value) {
                rows[rowKey][col.key] = col.value(rows[rowKey][col.key]);
            }
        }
    }

    return (
        <div style={{margin: '2rem', height: 'calc(100% - 4rem)', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex'}}>
                <Paper elevation={3} style={{padding: '1rem', margin: '0 2rem', marginLeft: 0}}>
                    <TextField
                        name="selected-month"
                        label="Monat"
                        type="month"
                        value={month}
                        onChange={onMonthChanged}
                    />
                </Paper>
                <Paper elevation={3} style={{padding: '1rem', margin: '0 2rem'}}>
                    <Typography variant="subtitle1">ohne NA</Typography>
                    Gesamt: {timePerMonth}<br />
                    Geleistet: {formatJiraTime(gelZeit)}<br />
                    Verbleibend: {formatJiraTime(diff)}<br />
                    <br />
                    Resttage (mit Sprintwechseltage): {restDays}<br />
                    h per d: {formatJiraTime(hPerD)}<br />
                    <br />
                    Resttage (ohne Sprintwechseltage): {restDaysWithoutSprintDays}<br />
                    h per d: {formatJiraTime(hPerDWithoutSprintDays)}<br />
                </Paper>
                <Paper elevation={3} style={{padding: '1rem', margin: '0 2rem'}}>
                    <Typography variant="subtitle1">inkl. NA</Typography>
                    <br />
                    Geleistet: {formatJiraTime(gelZeitWithNA)}<br />
                    Verbleibend: {formatJiraTime(diffWithNA)}<br />
                    <br />
                    Resttage (mit Sprintwechseltage): {restDays}<br />
                    h per d: {formatJiraTime(hPerDWithNA)}<br />
                    <br />
                    Resttage (ohne Sprintwechseltage): {restDaysWithoutSprintDays}<br />
                    h per d: {formatJiraTime(hPerDWithNAWithoutSprintDays)}<br />
                </Paper>
                <Paper elevation={3} style={{padding: '1rem', margin: '0 2rem'}}>
                    <TextField
                        label="Time per month"
                        value={timePerMonth}
                        onChange={onTimePerMonthChanged}
                    />
                    <br />
                    <br />
                    <TextField
                        label="Time per sprintday"
                        value={timePerSprintDay}
                        onChange={onTimePerSprintDayChanged}
                    />
                </Paper>
            </div>
            <div style={{margin: '1rem'}}/>
            <div style={{overflow: 'scroll', flex: 1}}>
                <Paper elevation={2} style={{height: '100%'}}>
                    <TableContainer style={{height: '100%'}}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {cols.map(col =>
                                        <TableCell
                                            key={col.key}
                                            style={{ minWidth: col.minWidth }}
                                        >
                                            {col.label}
                                        </TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map(row =>
                                    <TableRow hover key={row.key} className={isWeekend(row.key) ? 'bg-gray-200' : ''}>
                                        {cols.map(col =>
                                            <TableCell key={col.key} align="left">
                                                {col.editable ?
                                                    <TextField
                                                        name={`${row.key}_${col.key}`}
                                                        value={curInputs[`${row.key}_${col.key}`] !== undefined ? curInputs[`${row.key}_${col.key}`] : row[col.key]}
                                                        onChange={e => setCurInputs({...curInputs, [e.currentTarget.name]: e.currentTarget.value})}
                                                        onBlur={onChange}
                                                    />
                                                    : (col.value ? col.value(row[col.key]) : row[col.key])}
                                            </TableCell>
                                        )}
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>

        </div>
    );
}
export default App;
