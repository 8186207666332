import React from 'react';
import ReactDOM from 'react-dom';

import './assets/main.css';
import AppContainer from "./AppContainer";
import {setGlobal} from "reactn";
import dataService from './utils/data';
import authService from './utils/auth';

import * as Sentry from '@sentry/browser';

Sentry.init({dsn: "https://ed3fe24916db41c392e72f8fda9b8c03@o386474.ingest.sentry.io/5220819", release: 'dev'});

setGlobal({
    user: {loggedIn: false}
});

let appUuid = '4f0c2a71-df0c-46c5-99e1-112a3f8377d4';
if(process.env.NODE_ENV !== 'production') {
    appUuid += '-dev';
}
dataService.init(appUuid);
authService.init();

ReactDOM.render(
  <React.StrictMode>
      <AppContainer />
  </React.StrictMode>,
  document.getElementById('root')
);
