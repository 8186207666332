import React from "react"
import Login from "./Login";
import Default from "./Default";
import {
    BrowserRouter as Router, Redirect,
    Route,
    Switch
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import authService from "./utils/auth";

const AppContainer = () => {
    return (
        <Router>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <Route path="/logout">
                    <Logout />
                </Route>
                <ProtectedRoute path="/">
                    <Default />
                </ProtectedRoute>
            </Switch>
        </Router>
    );
}

const Logout = () => {
    authService.logout()
    return <Redirect to={{pathname: '/login'}}/>
};

export default AppContainer;
