import auth from './auth';

export function post(url, formData, withCredentials = true) {
    const data = new FormData();
    for (const [key, value] of Object.entries(formData)) {
        data.append(key, value);
    }
    const headers = {};
    if (withCredentials) {
        headers['jwt'] = auth.getToken();
    }
    return fetch(url, {
        method: 'post',
        cache: 'no-cache',
        body: data,
        headers
    });
}

export function put(url, body) {
    if (typeof body != 'string') {
        body = JSON.stringify(body);
    }

    return fetch(url, {
        method: 'put',
        cache: 'no-cache',
        body,
        headers: {
            'jwt': auth.getToken()
        }
    });
}

export function get(url, withCredentials = true) {
    const headers = {};
    if (withCredentials) {
        headers['jwt'] = auth.getToken();
    }
    return fetch(url, {
        method: 'get',
        cache: 'no-cache',
        headers
    });
}

export function req(method, url) {
    return fetch(url, {
        method: method,
        cache: 'no-cache',
        headers: {
            'jwt': auth.getToken()
        }
    });
}
